<template>
    <div>
        <multiselect
            :placeholder="$t('select')"
            v-model="selected"
            :options="options"
            :class="validateError != '' ? 'box-border-color' : ''"
            @input="handleInput($event, multiple)"
            label="text"
            track-by="value"
            :multiple="multiple"
            :close-on-select="!multiple"
            :clear-on-select="!multiple"
            :select-label="''"
            :selected-label="''"
            :deselect-label="''"
        >
            <template
                slot="selection"
                slot-scope="{ values, search, isOpen }"
                v-if="multiple"
            >
                <span class="multiselect__single" v-if="values.length && !isOpen">
	                {{ translateNSelected(values) }}
                </span>
            </template>
            <span slot="noOptions">
                {{ $t("no_options") }}
            </span>
            <span slot="noResult">
                {{ $t("no_result") }}
            </span>
        </multiselect>
        <span
            class="invalid-feedback-custom"
            v-show="validateError"
            v-html="validateError"
        >
        </span>
    </div>
</template>

<script>
import translateNSelected from "@/helpers/translateNSelected"
import handleInput from "@/helpers/handleInput"
import setSelected from "@/helpers/setSelected"

export default {
    props: {
        value: {
            default: null,
        },
        multiple: {
            default: false,
        },
        validateError: {
            type: String,
            default: "",
        }
    },
    watch: {
        value: function (newValue) {
            this.selected = this.setSelected(newValue, this.options, this.multiple)
        }
    },
    data() {
        return {
            selected: null,
            options: [
                {
                    text: this.$t('course'),
                    value: 'course'
                },
                {
                    text: this.$t('slot'),
                    value: 'slot'
                }
            ],
        }
    },
    methods: {
        translateNSelected: translateNSelected,
        handleInput: handleInput,
        setSelected: setSelected
    }
}
</script>
